import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import Confetti from "react-confetti";

import Router from "./router";
import i18n from "./translation";
import { useState } from "react";

const App = () => {
  const [showConfetti, setShowConfetti] = useState(localStorage.getItem('confettiShown') !== 'true');
  if(showConfetti) {
    setTimeout(() => {
      localStorage.setItem('confettiShown', 'true');
      setShowConfetti(false);
    }, 10000);
  }
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        {showConfetti && <Confetti />}
        <Router />
      </I18nextProvider>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
